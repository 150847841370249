import React from "react";
import "./App.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { DashboardPage } from "./pages/dashboard/dashboard.page";
import { GoalsPage } from "./pages/goals/goals.page";
import { BottomNavComponent } from "./components/bottom-nav/bottom-nav.component";
import { AccountPage } from "./pages/account/account.page";
import { SearchPage } from "./pages/search/search.page";
import { ShopPage } from "./pages/shop/shop.page";

function App() {
  return (
    <main>
      <BrowserRouter>
        <Switch>
          <Route path="/dashboard" component={DashboardPage} />
          <Route path="/goals" component={GoalsPage} />
          <Route path="/account" component={AccountPage} />
          <Route path="/search" component={SearchPage} />
          <Route path="/shop" component={ShopPage} />

          <Route path="**" render={() => <Redirect to="/dashboard" />} />
        </Switch>

        <BottomNavComponent />
      </BrowserRouter>
    </main>
  );
}

export default App;
