import React from "react";
import { HeaderComponent } from "../../components/header/header.component";
import {
  ChartLegend,
  GlassChart,
  GoalDepositCard,
  GoalDepositList,
  GoalHeader,
  GoalsBalance,
  GoalsDetails,
} from "./goals.styles";

function GoalsPage(): JSX.Element {
  return (
    <>
      <HeaderComponent title="My Goal" theme="white" />

      <article>
        <GoalHeader>
          <GlassChart>
            <svg viewBox="0 0 249 404" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M49.3413 0C46.6693 0 44.4695 2.10082 44.3466 4.76998L44.1269 9.53995C40.1981 94.8492 22.4779 145.097 7.61231 187.168C1.10772 205.577 -0.3498 223.49 0.0651671 237.395C0.273214 244.367 0.954376 250.42 1.74624 255.143C2.13649 257.471 2.58374 259.659 3.06624 261.558L3.02222 261.575C12.162 296.692 36.1878 325.802 67.9583 341.764L60.6816 372.55C56.9709 388.249 68.8796 403.3 85.0112 403.3H162.594C178.725 403.3 190.634 388.249 186.923 372.55L179.688 341.941C210.879 326.394 234.657 298.195 244.302 264.065C245.058 261.719 245.759 258.583 246.336 255.143C247.128 250.42 247.809 244.367 248.017 237.395C248.432 223.49 246.974 205.577 240.47 187.168C225.604 145.097 207.884 94.8492 203.955 9.53995L203.736 4.76999C203.613 2.10083 201.413 0 198.741 0H49.3413Z"
                fill="#434365"
              />
            </svg>
            <svg viewBox="0 0 249 404" fill="none">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M49.3413 0C46.6693 0 44.4695 2.10082 44.3466 4.76998L44.1269 9.53995C40.1981 94.8492 22.4779 145.097 7.61231 187.168C1.10772 205.577 -0.3498 223.49 0.0651671 237.395C0.273214 244.367 0.954376 250.42 1.74624 255.143C2.13649 257.471 2.58374 259.659 3.06624 261.558L3.02222 261.575C12.162 296.692 36.1878 325.802 67.9583 341.764L60.6816 372.55C56.9709 388.249 68.8796 403.3 85.0112 403.3H162.594C178.725 403.3 190.634 388.249 186.923 372.55L179.688 341.941C210.879 326.394 234.657 298.195 244.302 264.065C245.058 261.719 245.759 258.583 246.336 255.143C247.128 250.42 247.809 244.367 248.017 237.395C248.432 223.49 246.974 205.577 240.47 187.168C225.604 145.097 207.884 94.8492 203.955 9.53995L203.736 4.76999C203.613 2.10083 201.413 0 198.741 0H49.3413ZM68.3143 20C65.6681 20 63.4809 22.0833 63.3057 24.7237C58.0173 104.423 40.7396 153.446 26.4697 193.832C20.9872 209.348 19.6948 224.685 20.0563 236.799C20.2365 242.836 20.8251 247.984 21.4709 251.836C21.794 253.763 22.1241 255.322 22.4099 256.472C22.6549 257.458 22.8209 257.954 22.8627 258.079C22.8746 258.115 22.8764 258.12 22.8672 258.098L22.8088 258.123C35.1034 302.457 75.7514 335 124 335C173.442 335 214.902 300.828 226.051 254.813C226.233 253.95 226.424 252.955 226.611 251.836C227.257 247.984 227.846 242.836 228.026 236.799C228.387 224.685 227.095 209.348 221.612 193.832C207.343 153.446 190.065 104.423 184.776 24.7237C184.601 22.0833 182.414 20 179.768 20H68.3143ZM124 355C136.85 355 149.247 353.061 160.915 349.46L167.46 377.15C168.202 380.29 165.82 383.3 162.594 383.3H85.0112C81.7849 383.3 79.4032 380.29 80.1453 377.15L86.7173 349.345C98.4922 353.02 111.015 355 124 355Z"
                fill="#434365"
              />
            </svg>
          </GlassChart>

          <ChartLegend>
            <h1>50% Completed</h1>
            <h3>
              You need <strong>R3000.00</strong> more to reach your goal of{" "}
              <strong>R6,000.00</strong>
            </h3>
            <hr />
            <h5>
              Your goal bottle is <strong>Genfiddich 18</strong>
            </h5>
          </ChartLegend>
        </GoalHeader>

        <GoalsDetails>
          <GoalsBalance>
            <h4>Total Balance</h4>
            <h4>R3000.00</h4>
          </GoalsBalance>

          <h4>Deposit History</h4>
          
          <GoalDepositList>
            <GoalDepositCard className="positive">
              <div className="amount">R100.00</div>
              <div className="date">18/07/2021</div>
            </GoalDepositCard>

            <GoalDepositCard className="positive">
              <div className="amount">R50.00</div>
              <div className="date">18/06/2021</div>
            </GoalDepositCard>

            <GoalDepositCard className="positive">
              <div className="amount">R50.00</div>
              <div className="date">18/05/2021</div>
            </GoalDepositCard>

            <GoalDepositCard className="negative">
              <div className="amount">R50.00</div>
              <div className="date">23/04/2021</div>
            </GoalDepositCard>

            <GoalDepositCard className="positive">
              <div className="amount">R150.00</div>
              <div className="date">18/04/2021</div>
            </GoalDepositCard>

            <GoalDepositCard className="positive">
              <div className="amount">R100.00</div>
              <div className="date">18/03/2021</div>
            </GoalDepositCard>

            <GoalDepositCard className="positive">
              <div className="amount">R50.00</div>
              <div className="date">18/02/2021</div>
            </GoalDepositCard>

            <GoalDepositCard className="positive">
              <div className="amount">R50.00</div>
              <div className="date">18/01/2021</div>
            </GoalDepositCard>

            <GoalDepositCard className="negative">
              <div className="amount">R50.00</div>
              <div className="date">22/12/2020</div>
            </GoalDepositCard>

            <GoalDepositCard className="positive">
              <div className="amount">R150.00</div>
              <div className="date">18/12/2020</div>
            </GoalDepositCard>

            <GoalDepositCard className="positive">
              <div className="amount">R500.00</div>
              <div className="date">18/11/2020</div>
            </GoalDepositCard>

            <GoalDepositCard className="positive">
              <div className="amount">R220.00</div>
              <div className="date">18/10/2021</div>
            </GoalDepositCard>

            <GoalDepositCard className="positive">
              <div className="amount">R150.00</div>
              <div className="date">22/09/2020</div>
            </GoalDepositCard>

            <GoalDepositCard className="negative">
              <div className="amount">R150.00</div>
              <div className="date">18/08/2020</div>
            </GoalDepositCard>

            <GoalDepositCard className="positive">
              <div className="amount">R500.00</div>
              <div className="date">18/07/2020</div>
            </GoalDepositCard>

            <GoalDepositCard className="negative">
              <div className="amount">R220.00</div>
              <div className="date">18/06/2021</div>
            </GoalDepositCard>

            <GoalDepositCard className="positive">
              <div className="amount">R150.00</div>
              <div className="date">22/05/2020</div>
            </GoalDepositCard>

            <GoalDepositCard className="positive">
              <div className="amount">R150.00</div>
              <div className="date">18/05/2020</div>
            </GoalDepositCard>

            <GoalDepositCard className="positive">
              <div className="amount">R500.00</div>
              <div className="date">22/04/2020</div>
            </GoalDepositCard>

            <GoalDepositCard className="positive">
              <div className="amount">R100.00</div>
              <div className="date">18/03/2020</div>
            </GoalDepositCard>

            <GoalDepositCard className="positive">
              <div className="amount">R500.00</div>
              <div className="date">18/02/2020</div>
            </GoalDepositCard>
          </GoalDepositList>
        </GoalsDetails>
      </article>
    </>
  );
}

export { GoalsPage };
