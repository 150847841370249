import styled from "styled-components";

const GoalHeader = styled.section`
  padding: 20px 18px 45px 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background: var(--background-dark);
`;

const GlassChart = styled.div`
  width: 100px;
  height: auto;
  position: relative;

  svg {
    height: auto;
  }

  svg path {
    fill: var(--accent-gold);
  }

  svg:nth-child(1) {
    --percent-to-show: 50%;

    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    will-change: clip-path;
    animation: FillGlass cubic-bezier(0.455, 0.03, 0.515, 0.955) 500ms forwards
      100ms;
  }

  svg:nth-child(2) {
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
  }

  @keyframes FillGlass {
    from {
      clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
    }

    to {
      clip-path: polygon(
        0 calc(100% - var(--percent-to-show)),
        100% calc(100% - var(--percent-to-show)),
        100% 100%,
        0% 100%
      );
    }
  }
`;

const ChartLegend = styled.div`
  width: calc(100% - 100px);
  max-width: calc(100% - 100px);
  padding-left: 40px;
  color: var(--background-light);
  text-align: right;

  h1 {
    font-size: 22px;
    color: var(--accent-gold);
    margin-bottom: 10px;
  }

  h3 {
    font-size: 16px;
    font-weight: 300;
  }

  hr {
    border: none;
    margin: 15px 0 15px auto;
    width: 70%;
    background: var(--background-light);
    height: 1px;
  }

  h5 {
    font-size: 14px;
    font-weight: 300;
  }
`;

const GoalsDetails = styled.div`
  padding: 18px;

  h4 {
    font-weight: 600;
    color: var(--primary);
    font-size: 1em;
    margin-bottom: 10px;
  }
`;

const GoalDepositList = styled.ul`
  display: block;
  width: 100%;
  height: auto;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const GoalDepositCard = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  padding-left: 30px;

  &::after {
    content: "0";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    font-weight: bold;
    font-size: 1.2em;
  }

  &.negative::after {
    content: "-";
    color: var(--error);
  }

  &.positive::after {
    content: "+";
    color: var(--success);
  }
`;

const GoalsBalance = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: solid 1px #dddddd;
  font-size: 1.3em;

  h4:nth-child(2) {
    color: var(--accent-gold);
  }
`;

export {
  GoalHeader,
  GlassChart,
  ChartLegend,
  GoalsDetails,
  GoalDepositList,
  GoalDepositCard,
  GoalsBalance,
};
