import React from "react";
import { NavLink } from "react-router-dom";
import { HeaderComponent } from "../../components/header/header.component";

function DashboardPage(): JSX.Element {
  return (
    <>
      <HeaderComponent title="My Vault" theme="gold" />

      <article>
        <NavLink to="/goals">My Goal</NavLink>
      </article>
    </>
  );
}

export { DashboardPage };
