import React from "react";
import { HeaderComponent } from "../../components/header/header.component";

function AccountPage(): JSX.Element {
  return (
    <>
      <HeaderComponent title="My Account" theme="gold" />

      <article>
        <h1>Account</h1>
      </article>
    </>
  );
}

export { AccountPage };
