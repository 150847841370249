import React from "react";
import { HeaderComponent } from "../../components/header/header.component";

function SearchPage(): JSX.Element {
  return (
    <>
      <HeaderComponent title="Search" theme="white" />

      <article>
        <h1>Search</h1>
      </article>
    </>
  );
}

export { SearchPage };
