import styled from "styled-components";

const Header = styled.header`
  --color: var(--accent-gold);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 30px 18px;
  position: relative;
  overflow: hidden;
  background: var(--background-dark);

  &.white {
    --color: var(--background-light);
  }
`;

const HeaderIcon = styled.div`
  position: absolute;
  width: 10px;
  height: 35px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  svg {
    display: block;
    width: 100%;
    height: 100%;

    path {
      fill: var(--color);
    }
  }
`;

const HeaderLabel = styled.div`
  padding-left: 2px;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: var(--color);
`;

export { Header, HeaderIcon, HeaderLabel };
