import React from "react";
import { Header, HeaderIcon, HeaderLabel } from "./header.styles";

interface IHeaderComponentProps {
  title: string;
  theme?: "gold" | "white";
}

function HeaderComponent(props: IHeaderComponentProps) {
  return (
    <Header className={props.theme ?? "gold"}>
      <HeaderIcon>
        <svg viewBox="0 0 10 35" fill="none">
          <path
            d="M1.4591e-05 0H6.02094C6.23642 0 6.41382 0.182318 6.42373 0.413958L6.44145 0.827915C6.75828 8.23139 8.1873 12.5921 9.38612 16.2432C9.91068 17.8408 10.0282 19.3953 9.99475 20.6021C9.97797 21.2071 9.92304 21.7325 9.85918 22.1423C9.82771 22.3443 9.79164 22.5342 9.75273 22.699L9.75628 22.7005C9.01922 25.7481 7.08169 28.2744 4.51959 29.6596L5.10641 32.3313C5.40566 33.6938 4.4453 35 3.14438 35H1.4591e-05V33.2643H3.14438C3.40456 33.2643 3.59663 33.0031 3.53679 32.7306L3.00679 30.3176C2.05722 30.6365 1.04734 30.8083 0.000167179 30.8083C0.000217724 30.8083 0.000116634 30.8083 0.000167179 30.8083L1.4591e-05 29.0726C-3.59537e-05 29.0726 6.51357e-05 29.0726 1.4591e-05 29.0726C3.89098 29.0726 7.16913 26.2484 8.16062 22.401L8.15591 22.3988C8.15517 22.4007 8.15531 22.4002 8.15626 22.3972C8.15963 22.3863 8.17303 22.3432 8.19279 22.2576C8.21584 22.1578 8.24246 22.0225 8.26851 21.8553C8.32059 21.5211 8.36806 21.0743 8.38259 20.5503C8.41175 19.4991 8.30752 18.168 7.86539 16.8215C6.71462 13.3167 5.32127 9.06226 4.8948 2.14562C4.88067 1.91648 4.70428 1.73568 4.49089 1.73568H1.4591e-05V0Z"
            fill="var(--accent-gold)"
          />
        </svg>
      </HeaderIcon>

      <HeaderLabel>{props.title}</HeaderLabel>
    </Header>
  );
}

export { HeaderComponent };
