import { NavLink } from "react-router-dom";
import styled from "styled-components";

const NavbarList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  will-change: opacity;
  transition: opacity cubic-bezier(0.455, 0.03, 0.515, 0.955) 450ms;
  overflow: hidden;

  li {
    width: 70px;
    height: 70px;

    a {
      height: 100%;
      width: 100%;
      line-height: 70px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 25px;
        height: 25px;
        stroke: var(--background-light);

        path {
          stroke: var(--background-light);
        }
      }
    }

    &.active a svg path {
      stroke: var(--accent-gold);
    }
  }
`;

const NavbarBack = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  will-change: opacity;
  transition: opacity cubic-bezier(0.455, 0.03, 0.515, 0.955) 450ms;

  svg {
    display: block;
    width: 45px;
    height: 45px;
    stroke: var(--accent-gold);

    path {
      stroke: var(--accent-gold);
    }
  }
`;

const Navbar = styled.nav`
  display: block;
  width: calc(100vw - 36px);
  height: 70px;
  position: fixed;
  bottom: 18px;
  right: 18px;
  will-change: width;
  transition: width cubic-bezier(0.455, 0.03, 0.515, 0.955) 450ms;
  background: var(--background-dark);
  box-shadow: 0px 0px 8px 1px rgba(19, 19, 19, 0.1);
  border-radius: 15px;

  &.small {
    width: 70px;
    pointer-events: none;

    ${NavbarList} {
      opacity: 0;
      pointer-events: none;
    }

    ${NavbarBack} {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export { Navbar, NavbarList, NavbarBack };
